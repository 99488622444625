import * as React from "react";
import {FC, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './Footer.module.scss';
import CreatedBy from "../CreatedBy/CreatedBy";
import {Link} from "gatsby";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faInstagram, faPinterestP, faTiktok, faYoutube, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {Client, handleApiError} from "../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";
import {StaticImage} from "gatsby-plugin-image";
import {setShowCookieOverlay} from "../../stores/cookies/cookieSlice";
import BlurContent from "../BlurContent/BlurContent";

const mapState = (state) => ({
    Key: state.auth.Key
})

const mapDispatch = {
    setShowCookieOverlay
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

const Footer: ({Key, setShowCookieOverlay}: { Key: any; setShowCookieOverlay: any }) => JSX.Element = ({
                                                                                                           Key,
                                                                                                           setShowCookieOverlay
                                                                                                       }) => {
    const [companyData, setCompanyData] = useState([]);
    const [socials, setSocials] = useState([]);
    const [currUser, setCurrUser] = useState<any>([]);
    const [pages, setPages] = useState<any>([]);
    const [categories, setCategories] = useState([]);
    const [blurContentText, setBlurContentText] = useState('');
    const [blurContentButtonLink, setBlurContentButtonLink] = useState('');

    useEffect(() => {
        if (Key) {
            Client.Member.current(Key).then((res) => setCurrUser(res.data)).catch(handleApiError);
        }
        Client.Raw.get('Page').then((res) => setPages(res.data)).catch(handleApiError);
        Client.Raw.get('SiteConfig/1').then(
            handleSiteConfigResponse).catch(handleApiError);
        Client.Raw.get('Category/?filter[ParentID]=0&filter[isVendorOnly]=0').then((res) => setCategories(res.data)).catch(handleApiError);
    }, [])

    function handleSiteConfigResponse(res) {
        setSocials(res.data.SocialLinks);
        setCompanyData(res.data);
        setBlurContentText(res.data.OverFooterText);
        setBlurContentButtonLink(res.data.OverFooterButtonLink);
    }


    return (
        <div>
            {window.location.href.includes('ueber-uns') || window.location.href.includes('s/wer-kann-haendler-werden') ? null :
                <BlurContent title={'DU MÖCHTEST DEINE PRODUKTE VERMARKTEN?'}
                             text={blurContentText}
                             background={'https://api.fairstaerkt.dev5.cic.at/assets/Uploads/teaser_gruen__ScaleMaxWidthWzEyMDBd.jpg'}
                             imageUrl={'test'} TextAlign={'text-center'} ButtonLink={blurContentButtonLink}
                             ShowBGBottom={true}
                             ElementLink={''} buttonText={'JETZT FAIRKÄUFER:IN WERDEN'} ButtonEnabled={true}/>}

            <div className={style.holder + " container-fluid"}>
                <Container className={style.customContainer}>
                    <Row>
                        <Col lg={2} md={3}
                             className={style.normalFooter + ' pb-5 pb-lg-0 d-flex justify-content-between align-items-start flex-row flex-md-column'}>

                            <StaticImage src="../../images/fairStaerktNeu.png" className={style.logo}
                                         objectFit="contain" placeholder="tracedSVG" alt="FairStärkt"/>

                            <div className={"d-flex flex-wrap"}>
                                <a href={"https://www.trustedshops.at/bewertung/info_X4420777DC1B712B1C8A7FA6794A4B3F7.html"}
                                   target={"_blank"} className={style.footerImageItem}>
                                    <StaticImage src="../../images/trustbadge.png"
                                                 style={{width: "90%"}}
                                                 className={style.logo + ' ' + style.logoPost}
                                                 objectFit="contain" placeholder="tracedSVG" alt="FairStärkt"/>
                                </a>
                                <a className={style.footerImageItem}>
                                    <StaticImage src="../../images/wkoTransparent.png"
                                                 className={style.logo + ' ' + style.logoPost}
                                                 objectFit="contain" placeholder="tracedSVG" alt="FairStärkt"/>
                                </a>
                                <a className={style.footerImageItem}>
                                    <StaticImage src="../../images/neutral.png"
                                                 className={style.logo + ' ' + style.logoPost}
                                                 objectFit="contain" placeholder="tracedSVG" alt="FairStärkt"/>
                                </a>
                                <a href={"https://www.guetezeichen.at/zertifikate/shopzertifikat/fairstaerktat/"}
                                   target={"_blank"} className={style.footerImageItem}>
                                    <StaticImage src="../../images/ecommerce.png"
                                                 style={{height: "100%"}}
                                                 className={style.logo + ' ' + style.logoPost}
                                                 objectFit="contain" placeholder="tracedSVG" alt="FairStärkt"/>
                                </a>
                            </div>
                        </Col>

                        <Col lg={2} md={3}
                             className={style.mobileFooter + ' pt-5 pb-3 pb-lg-0 d-flex justify-content-between align-items-start flex-row flex-md-column'}>
                            <Row>
                                <Col xs={8}>
                                    <StaticImage src="../../images/fairStaerktNeu.png" className={style.logoPost}
                                                 objectFit="contain" placeholder="tracedSVG" alt="FairStärkt"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={12}
                             className={style.mobileFooter + ' pb-3 pb-lg-0 d-flex justify-content-between align-items-start flex-row flex-md-column'}>
                            <Row>
                                <Col xs={3}>
                                    <a href={"https://www.guetezeichen.at/zertifikate/shopzertifikat/fairstaerktat/"}
                                       target={"_blank"}>
                                        <StaticImage src="../../images/ecommerce.png"
                                                     style={{height: "100%"}}
                                                     className={style.logo + ' ' + style.logoPost}
                                                     objectFit="contain" placeholder="tracedSVG" alt="FairStärkt"/>
                                    </a>
                                </Col>
                                <Col xs={3} className={'d-flex justify-content-center align-items-center'}>
                                    <StaticImage src="../../images/neutral.png"
                                                 style={{height: "100%"}}
                                                 className={style.logo + ' ' + style.logoPost}
                                                 objectFit="contain" placeholder="tracedSVG" alt="FairStärkt"/>
                                </Col>
                                <Col xs={3}>
                                    <a href={"https://www.trustedshops.at/bewertung/info_X4420777DC1B712B1C8A7FA6794A4B3F7.html"}
                                       target={"_blank"}>
                                        <StaticImage src="../../images/trustbadge.png"
                                                     style={{width: "90%"}}
                                                     className={style.logo + ' ' + style.logoPost}
                                                     objectFit="contain" placeholder="tracedSVG" alt="FairStärkt"/>
                                    </a>
                                </Col>
                                <Col xs={3}>
                                    <StaticImage src="../../images/wkoTransparent.png"
                                                 style={{height: "100%"}}
                                                 className={style.logo + ' ' + style.logoPost}
                                                 objectFit="contain" placeholder="tracedSVG" alt="FairStärkt"/>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2} md={3} xs={6}>
                            <ul className={style.list + " list-unstyled"}>
                                <li className={style.heading}>
                                    Kontakt
                                </li>
                                <li>
                                    <p>fairstärkt GmbH</p>
                                </li>
                                <li>
                                    {/*// @ts-ignore*/}
                                    <p>{companyData.Street}</p>
                                </li>
                                <li>
                                    {/*// @ts-ignore*/}
                                    <p> {companyData.Region} </p>
                                </li>
                                <li>
                                    <p>Österreich</p>
                                </li>
                                <li className={style.heading + " pt-3"}>
                                    Ruf uns an!
                                </li>
                                <li>
                                    {/*// @ts-ignore*/}
                                    <a href={"tel:" + companyData.PhoneNr}
                                       style={{color: "unset", textDecoration: "unset"}}>
                                        {/*// @ts-ignore*/}
                                        <p>{companyData.PhoneNr} </p>
                                    </a>
                                </li>
                                <li className={style.heading + " pt-3"}>
                                    Schreib uns!
                                </li>
                                <li>
                                    {/*// @ts-ignore*/}
                                    <a href={"mailto:" + companyData.Email}
                                       style={{color: "unset", textDecoration: "unset"}}>
                                        {/*// @ts-ignore*/}
                                        <p>{companyData.Email}</p>
                                    </a>
                                </li>
                            </ul>
                        </Col>
                        <Col lg={2} md={3} xs={6}>
                            <ul className={style.list + " list-unstyled"}>
                                <li className={style.heading}>
                                    Links
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/"}>
                                        Home
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/marktplatz"}>
                                        Marktplatz
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/haendleruebersicht"}>
                                        Fairkäufer
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/ueber-uns"}>
                                        Unsere Nachhaltigkeit
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/s/unsere-vision"}>
                                        Unsere Werte und Vision
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/s/unsere-team"}>
                                        Unser Team
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/s/versand"}>
                                        Versand
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/kontakt"}>
                                        Kontakt
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/s/karriere"}>
                                        Karriere
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/s/empfehlungsprogramm"}>
                                        Empfehlungsprogramm
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/s/fuer-firmenkunden"}>
                                        Für Firmenkunden
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to={"/s/geschenkgutscheine"}>
                                        Geschenkgutscheine
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle + " pt-4"}>
                                    {currUser &&
                                        <>
                                            {!currUser.VendorID &&
                                                <Link to="/landingpage">
                                                    Fairkäufer werden
                                                </Link>
                                            }
                                        </>
                                    }
                                </li>
                                {/*{pages.map((menuItem: any, indexPages: number) => {*/}
                                {/*    return (*/}
                                {/*        <div key={indexPages}>*/}
                                {/*            {menuItem.ParentID === 0 && menuItem.URLSegment !== "home" ?*/}
                                {/*                <>*/}
                                {/*                    <li className={style.menuItemTitle}>*/}
                                {/*                        <Link*/}

                                {/*                            //menuItem.Title === 'Jetzt PartnerIn werden' ? '/register/vendor' : (menuItem.Title === ('Abo-Modelle') || menuItem.Title === ('HändlerInnen FAQ') ? `/s/${menuItem.URLSegment}` : `/${menuItem.URLSegment}`)*/}
                                {/*                            to={menuItem.Link}>*/}
                                {/*                            {menuItem.Title}*/}
                                {/*                        </Link>*/}
                                {/*                    </li>*/}
                                {/*                    {menuItem.Children ?*/}
                                {/*                        <>*/}
                                {/*                            {menuItem.Children.map((item: any, indexChildren: number) => {*/}
                                {/*                                return (*/}
                                {/*                                    <li key={indexChildren}*/}
                                {/*                                        className={style.menuListItem}>*/}
                                {/*                                        <Link to={`${item.Link}`}>*/}
                                {/*                                            <span> {item.Title} </span>*/}
                                {/*                                        </Link>*/}
                                {/*                                    </li>*/}
                                {/*                                )*/}
                                {/*                            })}*/}
                                {/*                        </> : null*/}
                                {/*                    }*/}
                                {/*                </>*/}
                                {/*                : null*/}
                                {/*            }*/}
                                {/*        </div>*/}
                                {/*    )*/}
                                {/*})}*/}
                            </ul>
                        </Col>
                        <Col lg={2} md={3} xs={6}>
                            <ul className={style.list + " list-unstyled"}>
                                <li className={style.heading}>
                                    <Link to={"/marktplatz"}>
                                        Marktplatz
                                    </Link>
                                </li>
                                {categories.map((category, indexCat) => {
                                    return (
                                        <li className={style.menuListItem} key={indexCat}>
                                            <Link to={`/marktplatz?catid=${category.ID}`}>
                                                <span> {category.Title} </span>
                                            </Link>
                                        </li>
                                    )
                                })}

                            </ul>
                        </Col>
                        <Col lg={2} md={3} xs={6}>
                            <ul className={style.list + " list-unstyled"}>
                                <li className={style.heading + ' mb-2'}>
                                    Folge uns:
                                </li>

                                {socials ?
                                    <>
                                        {socials.map((item, indexSocials) => {
                                            return (

                                                <li className={'me-2 mb-3 d-inline-block'} key={indexSocials}>
                                                    <a href={item.Link} target={"_blank"}>
                                                        <div className={style.followUsIcon}>
                                                            {item.Type === "0" ?
                                                                <FontAwesomeIcon
                                                                    icon={faFacebookF}/> : item.Type === "1" ?
                                                                    <FontAwesomeIcon
                                                                        icon={faInstagram}/> : item.Type === "2" ?
                                                                        <FontAwesomeIcon
                                                                            icon={faYoutube}/> : item.Type === "3" ?
                                                                            <FontAwesomeIcon
                                                                                icon={faPinterestP}/> : item.Type === "5" ?
                                                                                <FontAwesomeIcon
                                                                                    icon={faLinkedinIn}/> : item.Type === "4" ?
                                                                                    <FontAwesomeIcon
                                                                                        icon={faTiktok}/> : null}
                                                        </div>
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </> : null
                                }

                                <li className={style.heading + ' mt-3'}>
                                    Rechtliches
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to="/agb">
                                        AGB
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to="/datenschutz">
                                        Datenschutz
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to="/impressum">
                                        Impressum
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle}>
                                    <Link to="/widerrufsrecht">
                                        Widerrufsrecht
                                    </Link>
                                </li>
                                <li className={style.menuItemTitle} onClick={() => setShowCookieOverlay(true)}>
                                    <a href="javascript:void(0)">
                                        Cookie-Einstellungen
                                    </a>
                                </li>

                            </ul>
                        </Col>
                        <Col lg={2} md={3}>
                            <ul className={style.listGreen + " list-unstyled"}>
                                <li className={style.heading + ' mb-2'}>
                                    Zahlungsarten
                                </li>
                                <ul className="list-unstyled d-flex d-md-block justify-content-between align-items-center">
                                    <li className={'mb-0 mb-md-3'}>
                                        <StaticImage
                                            src={'../../images/apple.png'}
                                            placeholder="tracedSVG"
                                            alt="image"
                                            className={style.paymentMethod}
                                        />
                                    </li>
                                    <li className={'mb-0 mb-md-3'}>
                                        <StaticImage
                                            src={'../../images/eps.png'}
                                            placeholder="tracedSVG"
                                            alt="image"
                                            className={style.paymentMethod}
                                        />
                                    </li>
                                    <li className={'mb-0 mb-md-3'}>
                                        <StaticImage
                                            src={'../../images/mastercard.png'}
                                            placeholder="tracedSVG"
                                            alt="image"
                                            className={style.paymentMethod}
                                        />
                                    </li>
                                    <li className={'mb-0 mb-md-3'}>
                                        <StaticImage
                                            src={'../../images/visa.png'}
                                            placeholder="tracedSVG"
                                            alt="image"
                                            className={style.paymentMethod}
                                        />
                                    </li>
                                    <li>
                                        <StaticImage
                                            src={'../../images/sofort.png'}
                                            placeholder="tracedSVG"
                                            alt="image"
                                            className={style.paymentMethod}
                                        />
                                    </li>
                                </ul>
                            </ul>
                        </Col>
                    </Row>
                </Container>
                <CreatedBy/>
            </div>
        </div>
    )
}

export default connector(Footer);
