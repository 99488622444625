import * as React from 'react';
import {FC, useEffect, useState} from 'react';
// @ts-ignore
import {customContainer, dropDown, icon, iconRow, mobileFooter, mobileRow, searchBarSmall, cicText} from './layout.module.scss';
import Navigation from "./Navigation";
import LogoBar from "../LogoBar/LogoBar";
import TopInfoBar from "../TopInfoBar/TopInfoBar";
import WorkWithUs from "../WorkWithUs/WorkWithUs";
import Footer from "../Footer/Footer";
import Basket from "../Basket/Basket";
import SEO from "../SEO/SEO";
import {stack as MobileNav, stack as Basket123} from 'react-burger-menu';
import "./mobilenav.scss";
import {Link} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars, faChevronDown, faHeart} from "@fortawesome/pro-light-svg-icons";
import {Col, Dropdown, Row} from "react-bootstrap";
import {connect, ConnectedProps} from "react-redux";
import {Client, handleApiError} from "../../services/ApiService";
import {AxiosResponse} from "axios";
// @ts-ignore
import * as style from "./Navigation.module.scss";
import Cookies from "../Cookies/Cookies";
import SearchBar from "../SearchBar/SearchBar";

const mapState = ({auth, member, basket}) => ({
    auth,
    basket,
    member
})
const connector = connect(mapState, null);

type PropsFromRedux = ConnectedProps<typeof connector>

type LayoutProps = PropsFromRedux & {
    pageTitle?: string,
    description?: string,
    image?: string,
    children?: any,
    menuID?: number
}


const Layout: FC<LayoutProps> = ({pageTitle, description, image, children, menuID, auth, member}) => {
    const [visibility, setVisibility] = useState(false);
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoImage, setSeoImage] = useState('');
    const [mobileStatus, setMobileStatus] = useState(false);
    const [currUser, setCurrUser] = useState([]);

    useEffect(() => {
        if (member && auth.Key) {
            Client.Member.current(auth.Key).then((res: AxiosResponse<any>) => setCurrUser(res.data)).catch(handleApiError);
        }
    }, [])

    const [input, setInput] = useState('');

    function handleDataReturn(title, desc, image) {
        if (!pageTitle) {
            setSeoTitle(title);
        }
        if (!description) {
            setSeoDescription(description);
        }
        if (!image) {
            setSeoImage(image);
        }
    }

    const isPartiallyActive = ({isPartiallyCurrent}) => {
        return isPartiallyCurrent
            ? {className: style.active}
            : {}
    }

    const isActive = ({isCurrent}) => {
        return isCurrent ? {className: style.active} : {}
    }

    function handleInput(text?: string) {
        setInput(text);
    }

    function toggleBasket(){
        setVisibility(true);
        document.body.style.overflow = "hidden";
    }
    function toggleBasketFalse(){
        setVisibility(false);
        document.body.style.overflow = "visible";
    }


    return (
        <>
            <MobileNav onClose={() => {
                setMobileStatus(!mobileStatus)
            }} right isOpen={mobileStatus} customCrossIcon={<FontAwesomeIcon icon={faBars}/>}>
                <Row className={mobileRow}>
                    <Col xs={12}>
                        <Link to={'/'}>
                            <StaticImage
                                src={'../../images/fairStaerktNeu.png'}
                                placeholder="tracedSVG"
                                alt="image"
                            />
                        </Link>
                    </Col>
                </Row>
                <div className={"pt-5 d-block"}>
                    <Link to="/" getProps={isActive}>Home</Link>
                    <Link to="/marktplatz" getProps={isPartiallyActive}>Marktplatz</Link>
                    <Link to="/haendleruebersicht" getProps={isPartiallyActive}>Fairkäufer</Link>
                    <Link to="/ueber-uns" getProps={isPartiallyActive}>Unsere Nachhaltigkeit</Link>
                    <Link to="/s/unsere-vision" getProps={isPartiallyActive}>Unsere Werte und Vision</Link>
                    <Link to="/s/unsere-team" getProps={isPartiallyActive}>Unser Team</Link>
                    <Link to="/s/versand" getProps={isPartiallyActive}>Versand</Link>
                    <Link to="/kontakt" getProps={isPartiallyActive}>Kontakt</Link>
                    <Link to="/s/wer-kann-haendler-werden/" getProps={isPartiallyActive}>Jetzt Fairkäufer:in werden</Link>
                    {!member.MemberID ?
                        <Link to={"/register"}>Jetzt registrieren</Link>
                        : null}
                </div>
            </MobileNav>
            <Cookies/>

            {/*<SeoDataGetter menuID={menuID} returnData={handleDataReturn}/>*/}
            <SEO title={pageTitle ?? seoTitle}
                 description={description ? description : seoDescription}
                 image={image ? image : seoImage} lang={'de'}
            />


            {/*{visibility ?*/}
            {/*    <Basket setBasketVisibility={setVisibility}/> : null*/}
            {/*}*/}
            <Basket123 right onClose={toggleBasketFalse} isOpen={visibility} width={window.innerWidth > 991 ? '350px' : '100vw'}>
                <Basket visibility={visibility} setBasketVisibility={setVisibility}/>
            </Basket123>
            <TopInfoBar/>
            <div className={"container pt-2 " + style.navContainer}>
                <div className={"container " + style.customContainer}>
                    <LogoBar setMobileVisibility={() => setMobileStatus(!mobileStatus)}
                             setBasketVisibility={toggleBasket}/>
                    <Navigation/>
                </div>
                <div className={searchBarSmall}>
                    <SearchBar placeholder="Wonach suchst du?" input={input} returnInput={handleInput}/>
                </div>
            </div>

            {children}
            <Footer/>
        </>
    )
}

export default connector(Layout);
