import * as React from "react"
// @ts-ignore
import PropTypes from "prop-types"
import {Helmet} from "react-helmet"
import {useEffect} from "react";

function SEO({description, lang, meta, image: metaImage, title}) {
    const metaDescription = description;
    const image = metaImage;

    useEffect(() => {
        console.log(metaDescription)
    }, [metaDescription])

    return (

        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title ?? 'Plastikfrei in Österreich einkaufen | fairstärkt'}
            titleTemplate={`${title} |  fairstärkt`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]
                .concat(
                    metaImage
                        ? [
                            {
                                property: "og:image",
                                content: image,
                            },
                            {
                                name: "twitter:card",
                                content: "summary_large_image",
                            },
                        ]
                        : [
                            {
                                name: "twitter:card",
                                content: "summary",
                            },
                        ]
                )
                .concat(meta)}
       />

    )
}

SEO.defaultProps = {
    lang: `de`,
    meta: [],
    title: 'Plastikfrei in Österreich einkaufen | fairstärkt',
    description: ``,
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    pathname: PropTypes.string,
}

export default SEO
