import {Link} from "gatsby";
import * as React from "react";
import {useEffect, useState} from "react";
import {Col, Dropdown, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './Navigation.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faSearch, faShapes,} from "@fortawesome/pro-light-svg-icons";
import {Client, handleApiError} from "../../services/ApiService";
import classNames from "classnames/bind";
import Loading from "../Loading/Loading";
import CookieGtag from "../Cookies/CookieGtag";
import SearchBar from "../SearchBar/SearchBar";
import LogoBar from "../LogoBar/LogoBar";

const Navigation = ({}) => {
    const [categories, setCategories] = useState([]);
    const [open, setOpen] = useState(false);
    const [testprops, setTestprops] = useState([]);

    const [input, setInput] = useState('');


    const isPartiallyActive = (
        {
            isPartiallyCurrent
        }
    ) => {
        return isPartiallyCurrent
            ? {className: style.active}
            : {}
    }

    const isActive = ({isCurrent}) => {
        return isCurrent ? {className: style.active} : {}
    }

    function handleInput(text?: string) {
        setInput(text?.toString());
    }

    useEffect(() => {
        window.scrollTo(0,0);
        Client.Raw.get('Category/tree').then((res) => setCategories(res.data)).catch(handleApiError);
    }, [])

    return <nav className={"mt-3 d-none d-lg-block "}>
        <Row>
        <Col lg={7} className={style.navMenu}>
        <ul className={style.list + " list-unstyled row flex-nowrap align-items-center"}>
            <li className={"d-none d-lg-block"}>
                <Link to="/marktplatz/" getProps={isPartiallyActive}>Marktplatz</Link>
            </li>
            <li className={"d-none d-lg-block"}>
                <Link to="/haendleruebersicht/" getProps={isPartiallyActive}>Fairkäufer</Link>
            </li>
            <li className={"d-none d-lg-block"}>
                <Dropdown>
                    <Dropdown.Toggle className={style.navBtn} variant="success" id="dropdown-basic">
                        Über uns
                        <FontAwesomeIcon icon={faChevronDown} color="#004036" className={style.icon}/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={style.dropDownMenu} >
                        <Dropdown.Item href="/ueber-uns">Unsere Nachhaltigkeit</Dropdown.Item>
                        <Dropdown.Item href="/s/unsere-vision">Unsere Werte und Vision</Dropdown.Item>
                        <Dropdown.Item href="/s/unsere-team">Unser Team</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </li>
            <li className={' d-none d-lg-block'}>
                <Link to="/s/versand/" getProps={isPartiallyActive}>Versand</Link>
            </li>
            <li className={' d-none d-lg-block'}>
                <Link to="/kontakt/" getProps={isPartiallyActive}>Kontakt</Link>
            </li>
        </ul>
        </Col>
        <Col lg={5}>
            <SearchBar placeholder="Wonach suchst du?" input={input} returnInput={handleInput}/>
        </Col>
        </Row>
        <CookieGtag/>

    </nav>;
}

export default Navigation;
