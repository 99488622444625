import * as React from "react";
import {Col, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './CreatedBy.module.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/pro-solid-svg-icons";

const CreatedBy = () => {
    return (
        <div className={style.holder + " container-fluid py-3"}>
            <div className={"container"}>
                <Row className="align-items-center">
                    <Col lg={3} md={6} xs={6} className={style.firstElement}>
                        © {new Date().getFullYear()} fairStärkt
                    </Col>
                    <Col lg={6} className={"d-none d-lg-block"}></Col>
                    <Col lg={3} md={6} xs={6} className={style.lastElement}>
                        {/*<small className="mt-0 mb-0"> created with <FontAwesomeIcon icon={faHeart}
                                                                                    className={style.icon}/> by
                            <a className={style.cicText + " text-danger"}
                               rel="noreferrer noopener"
                               target="_blank"
                               href="https://www.cic.at">CIC</a>
                        </small>*/}
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default CreatedBy
