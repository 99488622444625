import * as React from "react";
import {FC} from "react";
import ContentLoader from "react-content-loader";
// @ts-ignore
import * as style from './BasketLoader.module.scss';

type LoadingProps = {
    amount?: number,
}

const BasketLoader: FC<LoadingProps> = (props) => {
    return (
        <div className={style.holder}>
            {Array.from(Array(props.amount ? props.amount : 1).keys()).map((loader, index) => {
                return <ContentLoader
                    speed={2}
                    width={320}
                    height={125}
                    viewBox="0 0 320 125"
                    backgroundColor="#8c8c8c"
                    foregroundColor="#d2d1d1"
                    {...props}
                    key={index}
                >
                    <circle cx="60" cy="66" r="37"/>
                    <rect x="115" y="40" rx="0" ry="0" width="152" height="8"/>
                    <rect x="115" y="60" rx="0" ry="0" width="44" height="33"/>
                    <rect x="175" y="70" rx="0" ry="0" width="90" height="8"/>
                </ContentLoader>
            })}
        </div>
    )
}

export default BasketLoader;
