import {Col, Dropdown, Row} from "react-bootstrap";
import * as React from "react";
import {FC, Fragment, useEffect, useState} from "react";
import SearchBar from "../SearchBar/SearchBar";
import {faBars, faBoxFull, faHeart, faUserCircle} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// @ts-ignore
import * as style from './LogoBar.module.scss';
import {Link} from "gatsby";
import {Client, handleApiError} from "../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";
import {setKey} from '../../stores/auth/authSlice';
import {StaticImage} from "gatsby-plugin-image";
import {AxiosResponse} from "axios";
import Navigation from "../Navigation/Navigation";

const mapState = ({member, auth, basket, bookmark}) => ({
    member,
    auth,
    basket,
    bookmark
})

const mapDispatch = {
    setKey,
}
const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

type LogoBarProps = PropsFromRedux & {
    setBasketVisibility(): void
    setMobileVisibility(status: boolean): void
}


const LogoBar: FC<LogoBarProps> = ({
                                       setBasketVisibility,
                                       member,
                                       auth,
                                       basket,
                                       setKey,
                                       setMobileVisibility,
                                       bookmark
                                   }) => {
    const [logoLink, setLogoLink] = useState('');
    const [currUser, setCurrUser] = useState<any>([]);
    const [basketAmount, setBasketAmount] = useState('');
    const [basketAmountInt, setBasketAmountInt] = useState(0);
    const [favouritesAmount, setFavouritesAmount] = useState('0');
    const [favouritesAmountInt, setFavouritesAmountInt] = useState(0);
    const [mobileStatus, setMobileStatus] = useState(false);
    const [vendor, setVendor] = useState({});

    const [small, setSmall] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 120) {
            setSmall(true)
        } else {
            setSmall(false)
        }
    }

    window.addEventListener('scroll', changeNav);

    useEffect(() => {
        if (member && auth.Key) {
            Client.Member.current(auth.Key).then((res: AxiosResponse<any>) => {
                setCurrUser(res.data)
                if (member.VendorID) {
                    Client.Raw.get('Vendor/' + member.VendorID).then((res) => {
                        setVendor(res.data);
                    }).catch(handleApiError)
                }
            }).catch(handleApiError);
        }
        if (member.MemberID) {
            /*Client.Auth.authGetWithAction(auth.Key, 'Member', member.MemberID, 'getBookmarkCollection').then((res) => {
                setFavouritesAmount(isNaN(res.data.Products.length) ? 0 : res.data.Products.length);
            }).catch(handleApiError);*/
            setFavouritesAmount(bookmark.Amount)
        }
        setBasketAmount(basket.BasketAmount)
        Client.Raw.get('SiteConfig/1').then(handleResult).catch(handleApiError);
    }, [])

    useEffect(() => {
        setBasketAmount(basket.BasketAmount)
    }, [basket.BasketAmount, member])

    useEffect(() => {
        console.log("basket Change:")
        console.log(basketAmount)
        setBasketAmountInt(parseInt(basketAmount))
    }, [basketAmount])

    useEffect(() => {
        setFavouritesAmount(bookmark.Amount)
    }, [bookmark, member])

    useEffect(() => {
        setFavouritesAmountInt(parseInt(bookmark.Amount))
    }, [bookmark])


    function handleResult(res) {
        setLogoLink(res.data.LogoLink);
    }


    return (
        <>
            <div className={small ? style.compactNav : '' + style.logoBarContainer}>
                <div className={small ? style.customSmallContainer + ' container' : style.customContainer + ' container'}>
                    <Row className="align-items-md-start align-items-lg-center " style={{minHeight: '4em'}}>
                        <Col lg={small ? 1 : 3} md={3} xs={6} className={style.logoContainer + " order-1 order-lg-1 text-left"} >
                            <Link to={'/'}>
                                <div className={small ? 'd-none' : ''}>
                                    <StaticImage
                                        src={'../../images/fairStaerktNeu.png'}
                                        className={style.logo}
                                        placeholder="tracedSVG"
                                        alt="image"
                                    />
                                </div>

                                <div className={!small ? 'd-none' : ''}>
                                    <StaticImage
                                        src={'../../images/fairstärktLogoSmallBold.jpg'}
                                        className={style.SmallLogo}
                                        placeholder="tracedSVG"
                                        alt="image"
                                    />

                                    <StaticImage
                                        src={'../../images/fairStaerktNeu.png'}
                                        className={style.SmallLogoMobile}
                                        placeholder="tracedSVG"
                                        alt="image"
                                    />
                                </div>
                            </Link>
                        </Col>
                        <Col lg={small ? 10 : 8} md={6} xs={12} className={" order-3 order-md-2 order-lg-2 mt-3 mt-md-0"}>
                            <div className={small ? '' : 'd-none'}>
                                <Navigation/>
                            </div>

                        </Col>
                        <Col lg={1} md={3} xs={6} className={style.iconRow + ' order-3 order-lg-3 d-none d-lg-flex'}>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FontAwesomeIcon icon={faUserCircle} color="#004036" className={style.icon}/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={style.dropDown}>
                                    {!auth.Key ?
                                        <>
                                            <Link to="/login">
                                                Login
                                            </Link>
                                            <Link to="/register">
                                                Registrierung
                                            </Link>
                                        </> :
                                        <>
                                            <Link to="/user">
                                                Mein Profil
                                            </Link>
                                            {/*// @ts-ignore*/}
                                            {!currUser.VendorID || vendor.Inactive ?
                                                null :
                                                <Link to="/user/vendor">
                                                    Händlerportal
                                                </Link>
                                            }
                                            <Link to={'/logout'} state={{name: currUser.FirstName}}>
                                                Logout
                                            </Link>
                                        </>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            {auth.Key ?
                                <div className={style.basketHolder}>
                                    <Link to="/favorites">

                                        <FontAwesomeIcon icon={faHeart} color="#004036" className={style.icon}/>
                                        {bookmark.BookmarkAmount > 0 ?

                                            <div
                                                className={style.basketAmountClass}>{bookmark.BookmarkAmount > 99 ? ':)' : bookmark.BookmarkAmount}</div>
                                            : null}

                                    </Link>
                                </div> : null
                            }
                            <div className={style.basketHolder} onClick={() => setBasketVisibility()}>
                                <div
                                    className={style.basketAmountClass}>{basketAmountInt > 99 ? ':)' : basketAmountInt}</div>
                                <FontAwesomeIcon icon={faBoxFull} color="#004036" className={style.icon}/>
                            </div>
                        </Col>
                        <Col xs={6} md={3}
                             className={"order-2 order-md-3 d-flex d-lg-none justify-content-lg-end justify-content-evenly align-items-center " + style.iconRow}
                        >
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">
                                    <FontAwesomeIcon icon={faUserCircle} color="#004036" className={style.icon}/>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={style.dropDown}>
                                    {!auth.Key ?
                                        <>
                                            <Link to="/login">
                                                Login
                                            </Link>
                                            <Link to="/register">
                                                Registrierung
                                            </Link>
                                        </> :
                                        <>
                                            <Link to="/user">
                                                Mein Profil
                                            </Link>
                                            {/*// @ts-ignore*/}
                                            {currUser.VendorID && !vendor.Inactive ?
                                                <Link to="/user/vendor">
                                                    Händlerportal
                                                </Link> : null
                                            }
                                            <Link to={'/logout'} state={{name: currUser.FirstName}}>
                                                Logout
                                            </Link>
                                        </>
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                            {auth.Key ?
                                <div className={style.basketHolder}>
                                    <Link to="/favorites">

                                        <FontAwesomeIcon icon={faHeart} color="#004036" className={style.icon}/>
                                        {bookmark.BookmarkAmount > 0 ?
                                            <div
                                                className={style.basketAmountClass}>{bookmark.BookmarkAmount > 99 ? ':)' : bookmark.BookmarkAmount}</div>
                                            : null}
                                    </Link>
                                </div> : null
                            }
                            <div className={style.basketHolder} onClick={() => setBasketVisibility()}>
                                <div
                                    className={style.basketAmountClass}>{basketAmountInt > 99 ? ':)' : basketAmountInt}</div>
                                <FontAwesomeIcon icon={faBoxFull} color="#004036" className={style.icon}/>
                            </div>
                            {/*<FontAwesomeIcon icon={faBoxFull} className={style.icon} style={{fontSize: '2em' , marginLeft: '0.75em'}}*/}
                            {/*                 onClick={() => setBasketVisibility()}/>*/}
                            <FontAwesomeIcon icon={faBars} className={style.icon}
                                             onClick={() => setMobileVisibility(!mobileStatus)}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default connector(LogoBar);
