import * as React from "react";
import {useEffect, useState} from "react";
// @ts-ignore
import * as style from './TopInfoBar.module.scss';
import {faEnvelope, faPhoneAlt} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Container, Row} from "react-bootstrap";
import {Client, handleApiError} from "../../services/ApiService";

const TopInfoBar = ({}) => {
    const [phoneNr, setPhoneNr] = useState('');
    const [email, setEmail] = useState('');

    const [small, setSmall] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 1){
            setSmall(true)
        }else {
            setSmall(false)
        }
    }

    window.addEventListener('scroll', changeNav);

    useEffect(() => {
        Client.Raw.get('SiteConfig/1').then(handleResult).catch(handleApiError);
    }, [])

    function handleResult(res) {
        setPhoneNr(res.data.PhoneNr);
        setEmail(res.data.Email);
    }

    return (
        <div className={style.compactNav }>
        <div className={style.container}>
            <Container className={style.customContainer}>
                <Row>
                    <Col lg={3} md={4}>
                        <a href={"tel:" + phoneNr} className={style.linkHolder}>
                            <FontAwesomeIcon icon={faPhoneAlt} className={style.icon}/>
                            <span className={style.link}>{phoneNr}</span>
                        </a>
                    </Col>
                    <Col lg={3} md={4}>
                        <a href={"mailto:" + email} className={style.linkHolder}>
                            <FontAwesomeIcon icon={faEnvelope} className={style.icon}/>
                            <span className={style.link}>{email}</span>
                        </a>
                    </Col>
                    <Col lg={6} md={4}>
                        <a href={'/s/wer-kann-haendler-werden'} className={style.linkHolder + ' d-flex justify-content-end me-3'}>
                            <span className={style.link}>Jetzt Fairkäufer:in werden</span>
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
        </div>
    )
}

export default TopInfoBar
