// extracted by mini-css-extract-plugin
export var SmallLogo = "LogoBar-module--SmallLogo--+U-JL";
export var SmallLogoMobile = "LogoBar-module--SmallLogoMobile--RFzHk";
export var basketAmountClass = "LogoBar-module--basketAmountClass--eQyp5";
export var basketHolder = "LogoBar-module--basketHolder--xhe-g";
export var compactNav = "LogoBar-module--compactNav--cpPZH";
export var customContainer = "LogoBar-module--customContainer--PNOWy";
export var dropDown = "LogoBar-module--dropDown--1WqbC";
export var fadeInUp = "LogoBar-module--fadeInUp--ZpAm3";
export var icon = "LogoBar-module--icon--SaCRJ";
export var iconRow = "LogoBar-module--iconRow--X052Q";
export var logo = "LogoBar-module--logo--R3SEc";
export var logoBarContainer = "LogoBar-module--logoBarContainer--feSia";
export var logoContainer = "LogoBar-module--logoContainer--7OkJw";