// extracted by mini-css-extract-plugin
export var basketAmountClass = "layout-module--basketAmountClass--mDTv1";
export var basketHolder = "layout-module--basketHolder--7gO6i";
export var cicText = "layout-module--cicText--U47B3";
export var container = "layout-module--container--MmQuj";
export var customContainer = "layout-module--customContainer--NWx6B";
export var dropDown = "layout-module--dropDown--hG1UO";
export var heading = "layout-module--heading--N0pxX";
export var icon = "layout-module--icon--TWmuE";
export var iconRow = "layout-module--iconRow--p1H1O";
export var logo = "layout-module--logo--33gUB";
export var mobileFooter = "layout-module--mobileFooter--pn6Xn";
export var mobileRow = "layout-module--mobileRow--1JQUa";
export var navLinkItem = "layout-module--nav-link-item--rayG9";
export var navLinkText = "layout-module--nav-link-text--NU0zC";
export var navLinks = "layout-module--nav-links--Y+xvL";
export var searchBarSmall = "layout-module--searchBarSmall--7nOAI";