// @ts-ignore
import * as React from 'react';
import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, navigate} from 'gatsby';
import {Client, handleApiError} from "../../services/ApiService";
import {Col, Dropdown, DropdownButton, FormControl, InputGroup, Row} from "react-bootstrap";
import classNames from "classnames/bind";
// @ts-ignore
import * as style from './SearchBar.module.scss';
import {faSearch, faShapes} from "@fortawesome/pro-light-svg-icons";
import Loading from "../Loading/Loading";
// @ts-ignore
import * as dropdownStyle from './dropdown.scss';
import Select from 'react-select';
import {array} from "yup";


export interface SearchBarProps {
    input: string
    placeholder?: string
    classNameExtension?: string,

    returnInput(input: string): void
}






const SearchBar: FunctionComponent<SearchBarProps> = ({input, placeholder, returnInput}) => {
    const [products, setProducts] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTimeout, setSearchTimeout] = useState(null);
    const [showSearchbar, setShowSearchbar] = useState(true);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY >= 120) {
                {document.getElementById('searchbar').style.width = '70%'}
                {document.getElementById('searchbar').style.float = 'right'}
                {document.getElementById('searchbar').style.right = '50px'}
            } else {
                {document.getElementById('searchbar').style.width = '100%'}
                {document.getElementById('searchbar').style.float = 'revert'}
                {document.getElementById('searchbar').style.right = '0'}
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        Client.Raw.get('Category/tree').then((res) => setCategories(res.data)).catch(handleApiError);
    }, [])

    const inputRef = useRef();

    const catSelectOptions = generateMapArray();

    const [selectedOption, setSelectedOption] = useState(null);

    const customSelectStyles = {
        placeholder: (provided) => ({
            ...provided,
            color: '#FFFFFF',
            marginLeft: '15px'
        }),

        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#FFFFFF' : '#16241a',
        }),

        control: (provided) => ({
            ...provided,
            borderRadius: '20px',
            backgroundColor: '#16241a',
            borderColor: 'transparent !important',
            boxShadow: 'none'
        }),

        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),

        input: (provided) => ({
            ...provided,
            color: '#FFFFFF',
            marginLeft: '15px'
        }),

        groupHeading: (provided) => ({
            ...provided,
            color: '#FFFFFF',
            fontSize: '100%'
        }),

        menu: (provided) => ({
            ...provided,
            backgroundColor: '#16241a',
            color: '#cc6615',
            zIndex: '99',
        }),
    }

    function handleChange(e) {
        setSelectedOption(e.value);
        navigate(e.value);
    }

    function generateMapArray(): any[] {
        let options: any = [];

        categories.map((cat) => {
            let obj = {
                label: cat.Title,
                value: cat.Link,
                options: getSubCategories(cat)
            };
            options.push(obj);
        })

        return options;
    }

    function getSubCategories(cat): any[] {
        let catArray = [];

        catArray.push({label: 'Alle anzeigen', value: cat.Link})

        cat.Children?.forEach((item) => {
            catArray.push({label: item.Title, value: item.Link})
        })

        return catArray;
    }

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            // @ts-ignore
            if (inputRef.current && !inputRef.current.contains(event.target)) {
                setShowSearchbar(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [])

    useEffect(() => {
        handleSearchTimeout();
        returnInput(searchTerm)
    }, [searchTerm])

    function handleSearchTimeout() {
        clearTimeout(searchTimeout);
        setSearchTimeout(setTimeout(() => handleSearch(searchTerm), 250));
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {

            console.log(input)

            let searchEvent = {
                'event': 'search',
                'data': {
                    'search_term': input,
                }
            };

            //@ts-ignore
            if (window.dataLayer) {
                //@ts-ignore
                window.dataLayer.push(searchEvent);
            }
            console.log("do some gtag stuff on search maybe yippy")
            console.log(window.dataLayer)

            // Used to navigate to searchpage, (/search?q={term}), not necessary anymore
            navigate('/marktplatz?search=' + encodeURIComponent(input));
        }
    }

    function handleSearch(term: string) {
        if (term === '') {
            setProducts([]);
            setVendors([]);
        } else {
            let params = {
                'filter[Hidden]': 0,
                'filter[ID:not]': 0,
                'filterAny[Title:PartialMatch]': term,
            };
            Client.Raw.get('Product/getProductsForSearchBar/?filter[Draft]=0', {params}).then((res) => {
                setProducts(res.data);
            }).catch(handleApiError)
            Client.Raw.get('Vendor/getVendorsForSearchBar/?filter[Status]=Active', {params}).then((res) => {
                setVendors(res.data);
            })
            Client.Raw.get('Category/tree/', {params}).then((res) => {
                setCategories(res.data);
            })
        }
    }

    function handleInput(event) {
        setSearchTerm(event)
    }


    let cl = classNames.bind(style)
    return (
        <div ref={inputRef}>
            <div id={'searchbar'} className={style.smallerSearchbar + " position-relative"}

            >
                <input
                    className={cl({
                        searchBarResults: products.length,
                        searchBar: !products.length
                    })}
                    onClick={() => setShowSearchbar(true)}
                    key="searchbar"
                    value={searchTerm}
                    placeholder={placeholder}
                    onChange={(e) => handleInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                >
                </input>
                <FontAwesomeIcon icon={faSearch} color="white" className={style.Icon}/>
                {(products.length || vendors.length) && showSearchbar ?
                    <div className={style.prodResults}>
                        <p>Produkte</p>
                        {products.map((prod, index) => {
                            return (
                                index <= 5 ?
                                    <Link to={`/${prod.CategoryURLTitle}/${prod.URLTitle}`} key={index}
                                          className={style.resLink}>
                                        <Row className={style.resRow}>
                                            <Col sm={2} xs={12}>
                                                <img src={prod.PreviewImage}
                                                     className={"img-fluid " + style.prevImage}/>
                                            </Col>
                                            <Col sm={4} xs={6} className={style.resTitle}>
                                                <span> {prod.Title} </span>
                                            </Col>
                                            <Col sm={3} xs={6} className={style.resCat}>
                                                <span>{prod.CategoryTitle}</span>
                                            </Col>
                                            <hr/>
                                        </Row>
                                    </Link>
                                    : null
                            )
                        })}

                        {vendors.length > 0 ? <p>Händler</p> : null}


                        {vendors.map((vendor, index) => {
                            return (
                                index <= 3 ?
                                    <Link to={`/haendleruebersicht/${vendor.URLSegment}`} key={index}
                                          className={style.resLink}>
                                        <Row className={style.resRow}>
                                            <Col lg={2}>
                                                <img src={vendor.PreviewImage}
                                                     className={"img-fluid " + style.prevImage}/>
                                            </Col>
                                            <Col lg={4} className={style.resTitle}>
                                                <span> {vendor.CompanyName} </span>
                                            </Col>
                                            <Col lg={3} className={style.resCat}>
                                                <span>Händler</span>
                                            </Col>
                                            <hr/>
                                        </Row>
                                    </Link>
                                    : null
                            )
                        })}
                    </div> : null
                }
            </div>
            {/*<div className={"position-relative d-block d-lg-none"} ref={inputRef}>*/}
            {/*    <FormControl*/}
            {/*        type={"input"}*/}
            {/*        aria-describedby="basic-addon1"*/}
            {/*        className={cl({*/}
            {/*            searchBarResults: products.length,*/}
            {/*            searchBar: !products.length*/}
            {/*        })}*/}
            {/*        onClick={() => setShowSearchbar(true)}*/}
            {/*        key="searchbar"*/}
            {/*        value={searchTerm}*/}
            {/*        placeholder={placeholder}*/}
            {/*        onChange={(e) => handleInput(e.target.value)}*/}
            {/*        onKeyDown={handleKeyDown}*/}
            {/*    />*/}
            {/*    <FontAwesomeIcon icon={faSearch} color="white" className={style.Icon}/>*/}
            {/*</div>*/}
        </div>
    );
}

export default SearchBar;
