import * as React from "react";
import {FC, useEffect, useState} from "react";
import {
    setFunctionalCookies,
    setPerformanceCookies,
    setShowCookieOverlay,
    setTargetingCookies
} from "../../stores/cookies/cookieSlice";
import {connect, ConnectedProps} from "react-redux";
import {Button, Form} from "react-bootstrap";
import {Link} from "gatsby";
import './Cookies.scss';

const mapState = ({cookie}) => ({
    cookie
})

const mapDispatch = {
    setFunctionalCookies,
    setPerformanceCookies,
    setTargetingCookies,
    setShowCookieOverlay,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

const Cookies: FC<PropsFromRedux & any> = (
    {
        cookie,
        setPerformanceCookies,
        setFunctionalCookies,
        setTargetingCookies,
        setShowCookieOverlay
    }
) => {
    const [performanceCookies, setInternalPerformanceCookies] = useState(false);
    const [functionalCookies, setInternalFunctionalCookies] = useState(false);
    const [targetingCookies, setInternalTargetingCookies] = useState(false);

    useEffect(() => {
        setInternalFunctionalCookies(cookie.FunctionalCookies);
        setInternalPerformanceCookies(cookie.PerformanceCookies);
        setInternalTargetingCookies(cookie.TargetingCookies);
    }, [])

    function handleDSGVOSave() {
        setPerformanceCookies(performanceCookies);
        setFunctionalCookies(functionalCookies);
        setTargetingCookies(targetingCookies);


        // @ts-ignore
        window.dataLayer.push({'event': 'fs.performanceCookies' + (performanceCookies.toString().toUpperCase())})
        // @ts-ignore
        window.dataLayer.push({'event': 'fs.functionalCookies' + (functionalCookies.toString().toUpperCase())})
        // @ts-ignore
        window.dataLayer.push({'event': 'fs.targetingCookies' + (targetingCookies.toString().toUpperCase())})

        setShowCookieOverlay(false)
    }


    function handleDSGVOAllowAll() {
        setInternalPerformanceCookies(true);
        setInternalFunctionalCookies(true);
        setInternalTargetingCookies(true);
        // @ts-ignore
        window.dataLayer.push({'event': 'fs.performanceCookiesTRUE'})
        // @ts-ignore
        window.dataLayer.push({'event': 'fs.functionalCookiesTRUE'})
        // @ts-ignore
        window.dataLayer.push({'event': 'fs.targetingCookiesTRUE'})
        setFunctionalCookies(true);
        setPerformanceCookies(true);
        setTargetingCookies(true);

        setShowCookieOverlay(false)
    }

    return (
        <>
            {cookie.ShowCookieOverlay ?
                <>
                    <div className='dsgvo-widget__popup-bg'/>
                    <div className='dsgvo-widget__popup'>
                        <span className="dsgvo-widget__popup-welcome">
                            Cookie-Einstellungen
                        </span>
                        <div className='dsgvo-widget__popup-main'>
                            <div className="dsgvo-widget__popup-text">
                                Wir verwenden Cookies, um dir ein optimales Webseiten-Erlebnis zu bieten. Dazu zählen
                                Cookies, die für den Betrieb der Seite und für die Steuerung unserer kommerziellen
                                Unternehmensziele notwendig sind, sowie solche, die lediglich zu anonymen
                                Statistikzwecken, für Komforteinstellungen oder zur Anzeige personalisierter Inhalte
                                genutzt werden. Du kannst selbst entscheiden, welche Kategorien du zulassen möchtest.
                                Bitte beachte, dass auf Basis deiner Einstellungen womöglich nicht mehr alle
                                Funktionalitäten der Seite zur Verfügung stehen.

                                <br/>
                                <br/><Link to="/datenschutz">Weitere Informationen findest du unter
                                »Datenschutz«</Link>
                                <br/><Link to="/impressum">Impressum</Link>

                            </div>
                            <div className='dsgvo-widget__popup-categories'>
                                <div className='dsgvo-widget__popup-categories-item'>
                                    <div className='dsgvo-widget__popup-categories-item-name'>
                                        <strong>Essenzielle Cookies</strong>
                                        <div className="dsgvo-widget__popup-categories-item-count">
                                            - Session cookies
                                            <br/>- Login cookies
                                            <br/>
                                        </div>
                                    </div>
                                    <div className='dsgvo-widget__popup-categories-item-toggle'>
                                        <Form.Check disabled={true} defaultChecked={true}/>
                                    </div>
                                </div>
                                <div className='dsgvo-widget__popup-categories-item'>
                                    <div className='dsgvo-widget__popup-categories-item-name'>
                                        <strong>Performance Cookies</strong>
                                        <div className="dsgvo-widget__popup-categories-item-count">
                                            - Google Analytics
                                        </div>
                                    </div>
                                    <div className='dsgvo-widget__popup-categories-item-toggle'>
                                        <Form.Check disabled={false} defaultChecked={performanceCookies}
                                                    onChange={(event) => {
                                                        setInternalPerformanceCookies(event.target.checked)
                                                    }
                                                    }/>
                                    </div>
                                </div>
                                <div className='dsgvo-widget__popup-categories-item'>
                                    <div className='dsgvo-widget__popup-categories-item-name'>
                                        <strong>Funktionelle Cookies</strong>
                                        <div className="dsgvo-widget__popup-categories-item-count">
                                            - Google Maps
                                        </div>
                                    </div>
                                    <div className='dsgvo-widget__popup-categories-item-toggle'>
                                        <Form.Check disabled={false} defaultChecked={functionalCookies}
                                                    onChange={(event) => setInternalFunctionalCookies(event.target.checked)}/>
                                    </div>
                                </div>
                                <div className='dsgvo-widget__popup-categories-item'>
                                    <div className='dsgvo-widget__popup-categories-item-name'>
                                        <strong>Targeting Cookies</strong>
                                        <div className="dsgvo-widget__popup-categories-item-count">
                                            - Facebook Pixel<br/>
                                            - Google Ads
                                        </div>
                                    </div>
                                    <div className='dsgvo-widget__popup-categories-item-toggle'>
                                        <Form.Check disabled={false} defaultChecked={targetingCookies}
                                                    onChange={(event) => setInternalTargetingCookies(event.target.checked)}/>
                                    </div>
                                </div>
                                <div className="dsgvo-widget__popup-footer w-100 d-md-flex justify-content-between">

                                    <Button variant='primary' onClick={handleDSGVOAllowAll} className='mt-2 mt-md-0'>
                                        Alle <br/> Akzeptieren
                                    </Button>
                                    <Button variant='secondary' onClick={handleDSGVOSave} className='mt-2 mt-md-0'>
                                        Einstellungen speichern
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                : null
            }
        </>

    )
}

export default connector(Cookies);
