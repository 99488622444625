// extracted by mini-css-extract-plugin
export var basketButton = "Basket-module--basketButton--YNvNg";
export var basketButtonIcon = "Basket-module--basketButtonIcon--UN3hg";
export var basketButtons = "Basket-module--basketButtons--6DOzV";
export var basketContainer = "Basket-module--basketContainer--6mNIM";
export var basketFooter = "Basket-module--basketFooter--gle-0";
export var basketHeading = "Basket-module--basketHeading--ERwOT";
export var basketHeadline = "Basket-module--basketHeadline--+UqfU";
export var basketImage = "Basket-module--basketImage--tyYSl";
export var basketInner = "Basket-module--basketInner--Go3Ez";
export var basketItem = "Basket-module--basketItem--KJLPT";
export var basketItems = "Basket-module--basketItems--5v4wE";
export var basketPrice = "Basket-module--basketPrice--joQFp";
export var closeIcon = "Basket-module--closeIcon--Sy943";
export var error = "Basket-module--error--WVSQO";
export var fairContainer = "Basket-module--fairContainer--HOlMQ";
export var fairIcon = "Basket-module--fairIcon--rvx3X";
export var fakeDiv = "Basket-module--fakeDiv--k0vnv";
export var itemNameHolder = "Basket-module--itemNameHolder--YkVgB";
export var noLinkStyling = "Basket-module--noLinkStyling--EqEhx";
export var orderButton = "Basket-module--orderButton--M7Ddg";
export var removeItem = "Basket-module--removeItem--xLg9b";
export var selection = "Basket-module--selection--A3r18";
export var selectionHolder = "Basket-module--selectionHolder--CBoRR";
export var spinner = "Basket-module--spinner--7sBDE";
export var textRight = "Basket-module--textRight--LKO3D";
export var vendorTitle = "Basket-module--vendorTitle--H-nbi";