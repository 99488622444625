// extracted by mini-css-extract-plugin
export var customContainer = "Footer-module--customContainer--+Y78j";
export var followUsIcon = "Footer-module--followUsIcon--gmJFh";
export var footerImageItem = "Footer-module--footerImageItem--su5or";
export var heading = "Footer-module--heading--NDan0";
export var headingGreen = "Footer-module--headingGreen--3+8+t";
export var holder = "Footer-module--holder--XE9e4";
export var list = "Footer-module--list--WUpjB";
export var listGreen = "Footer-module--listGreen---2-IK";
export var logoPost = "Footer-module--logoPost--S48TX";
export var menuItemTitle = "Footer-module--menuItemTitle--61wWO";
export var menuListItem = "Footer-module--menuListItem--3yCgK";
export var mobileFooter = "Footer-module--mobileFooter--V92L4";
export var normalFooter = "Footer-module--normalFooter--WzHKb";
export var paymentMethod = "Footer-module--paymentMethod--KUaMP";