// extracted by mini-css-extract-plugin
export var Icon = "SearchBar-module--Icon--k1DSV";
export var ShapeSearch = "SearchBar-module--ShapeSearch--NwFhe";
export var dropdown = "SearchBar-module--dropdown--AjcGa";
export var prevImage = "SearchBar-module--prevImage--9fOli";
export var prodResults = "SearchBar-module--prodResults--edDNo";
export var resCat = "SearchBar-module--resCat--JwbZG";
export var resImage = "SearchBar-module--resImage--FDHWo";
export var resLink = "SearchBar-module--resLink--FL1k+";
export var resRow = "SearchBar-module--resRow--HO6fI";
export var resTitle = "SearchBar-module--resTitle--1LbP8";
export var searchBar = "SearchBar-module--searchBar--SrgA9";
export var searchBarResults = "SearchBar-module--searchBarResults--k1DHh";