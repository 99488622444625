import * as React from "react";
// @ts-ignore
import * as style from './BlurContent.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Link} from "gatsby";

import {Button} from "bootstrap";

type BlurContentProps = {
    title: string,
    text: string,
    background: string
    imageUrl: string
    file: string
    TextAlign: string
    ButtonLink: string
    ShowBGBottom: boolean
    ElementLink: string
    buttonText: string
    ButtonEnabled: boolean
}


function BlurContent(props: BlurContentProps) {
    return <>
        {props.ElementLink ? <><a className={style.nostyle} href={props.ElementLink}><Container
                className={style.customContainer + (props.ShowBGBottom ? ' ' + style.bgBottom : '')}
                style={{backgroundImage: `url(${props.background})`}}>
                <Row className={"align-items-center " + style.containerLayer}>
                    {props.text ?
                        <Col lg={(props.TextAlign == 'text-right' ? {offset: 6, span: 6} : {offset: 0, span: 6} ? props.TextAlign == 'text-center' : {offset: 4, span: 4})}
                             md={(props.TextAlign == 'text-right' ? {offset: 6, span: 6} : {offset: 0, span: 6} ? props.TextAlign == 'text-center' : {offset: 4, span: 4})}
                             sm={12}
                             xs={12}
                             className={style.textContainer + " d-flex align-items-center mb-3 mb-md-0 " + props.TextAlign}>
                            <div className={'mb-4'}>
                                {props.title && <h2>{props.title}</h2>}
                                <ReactMarkdown children={props.text} remarkPlugins={[remarkGfm]}/>
                                {props.ButtonEnabled ?
                                    <Link to={props.ButtonLink}
                                       className={(props.title == 'UNSERE MISSION.' ? style.customButtonPink : style.customButton)}> {props.buttonText}</Link> : null}
                            </div>


                        </Col> : null}
                </Row>
            </Container>
            </a>
            </>
            :
            <>
                <div
                    className={style.customContainer + " position-relative " + (props.ShowBGBottom ? '  ' + style.bgBottom : '')}
                    style={{backgroundImage: `url(${props.background})`}}>

                    <div className={"align-items-center " + style.containerLayer}>
                    </div>

                    <Container className={style.textContainer + " position-relative"}>
                        <Row className={style.blueContentBlock + " align-items-center"}>
                            {props.text ?
                                <Col lg={(props.TextAlign == 'text-right' ? {offset: 6, span: 6} : props.TextAlign == 'text-left' ? {offset: 0, span: 6} : props.TextAlign == 'text-center' ? {offset: 2, span: 8} : 12)}
                                     md={(props.TextAlign == 'text-right' ? {offset: 6, span: 6} : props.TextAlign == 'text-left' ? {offset: 0, span: 6} : props.TextAlign == 'text-center' ? {offset: 2, span: 8} : 12)}
                                     sm={12}
                                     xs={12}
                                     className={" d-flex align-items-center mb-3 mb-md-0 " + props.TextAlign}>
                                    <div className={'mb-4'}>
                                        {props.title && <h2>{props.title}</h2>}
                                        <ReactMarkdown children={props.text} remarkPlugins={[remarkGfm]}/>
                                        {props.file ?
                                            <div style={{paddingBottom: "30px"}}>
                                            <Link to={props.file} target={"_blank"}
                                                  className={(props.title == 'UNSERE MISSION.' ? style.customButtonPink : style.customButton)}> Zur Datei  </Link></div> : null}
                                        {props.ButtonEnabled ?
                                            <div className={(props.title == 'UNSERE MISSION.' ? style.customButtonPink : style.customButton)}>
                                            <Link to={props.ButtonLink}> {props.buttonText}  </Link>
                                            </div> : null}
                                    </div>
                                </Col> : null}
                        </Row>
                    </Container>
                </div></>}
    </>;
}

export default BlurContent;
